import React, { useState } from "react";
import "./login.css";
import logo from "../../../assets/images/logo.jpeg";
import { login } from "../../../redux/apiCalls";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

const Login = () => {
  const [user, setUser] = useState({});

  const dispatch = useDispatch();

  const handleChange = ({ currentTarget }) => {
    const { name, value } = currentTarget;
    setUser({ ...user, [name]: value });
  };

  const handleClick = () => {
    login(dispatch, user);
  };

  return (
    <div className="login">
      <div className="part_left">
        <img src={logo} />
      </div>
      <div className="part_right">
        <div className="content_form">
          <div className="container_formulaire">
            <h1>Connexion</h1>
            <p>Connectez-vous et acceder au panel admin!</p>
            <div action="">
              <input
                type="email"
                placeholder="Email adresse"
                name="email"
                onChange={handleChange}
                required
              />
              <input
                type="password"
                placeholder="Mot de passe"
                name="password"
                onChange={handleChange}
                required
              />
              <div className="content_btn">
                <button onClick={() => handleClick()}>Se Connecter</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
