import {
  loginFailure,
  loginStart,
  loginSuccess,
  logoutNotAdmin,
  registerFailure,
  registerStart,
  registerSuccess,
  logout,
} from "./userRedux";
import { publicRequest } from "../requestMethods";

export const login = async (dispatch, user) => {
  dispatch(loginStart());
  try {
    const res = await publicRequest.post("/auth/login", user);
    if (res.data.role="admin") {
      dispatch(loginSuccess(res.data));
    } else {
      dispatch(logoutNotAdmin());
    }
  } catch (err) {
    dispatch(loginFailure());
  }
};

export const register = async (dispatch, user) => {
  dispatch(registerStart());
  try {
    const res = await publicRequest.post("/register", user);
    dispatch(registerSuccess(res.data));
  } catch (err) {
    dispatch(registerFailure());
  }
};

export const loGout = async (dispatch, user) => {
  try {
    dispatch(logout());
    
  } catch (err) {
    console.log(err)
  }
};
