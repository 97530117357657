import React from 'react'
import './condition.css'

const Condition = () => {
  return (
    <div className='content_condition'>
        <p>© QA ProConsulting  All Rights Reserved.</p>
        <p>Support</p>
        <p>Licence</p>
        <p>Terms of Use</p>
    </div>
  )
}

export default Condition