import React, { useState, useEffect } from 'react';
import './compte.css';
import logo from '../../assets/images/logo.jpeg';
import { NavLink } from 'react-router-dom';
import { BiHomeAlt } from "react-icons/bi";
import { TfiBag } from "react-icons/tfi";
import { AiOutlineStock } from "react-icons/ai";
import { FaUser } from "react-icons/fa6";
import { RiBookReadLine, RiDownload2Line } from "react-icons/ri";
import Namepages from '../../compotences/namepages/Namepages';
import Condition from '../../compotences/condition/Condition';
import axios from "axios";
import { useDispatch } from "react-redux";
import { loGout } from '../../redux/apiCalls';


const Compte = ({ userRequest, user }) => {

  const [userInfos, setUserInfos] = useState("");
  const [userUpdate, setUserUpdate] = useState("");
  const [password, setPassword] = useState("");
  const [newpassword, setNewPassword] = useState("");

  const dispatch = useDispatch();


  const handleClick = () => {
    loGout(dispatch);
  };


  const handleSubmit = async () => {
    try {
      const res = await axios.put(
        `http://localhost:5050/api/auth/resetpassword/${user.id}`, {
          password: password,
          newpassword: newpassword
        }
      );
      alert("Password changed successfully!");
    } catch (error) {
      alert("Failed to change password");
    }
  };

  const handleChange = ({ currentTarget }) => {
    const { name, value } = currentTarget;
    setUserUpdate({ ...userUpdate, [name]: value });
  };

  const handleSave = async () => {
    try {
      await userRequest.put(`/users/${user.id}`, userUpdate);
      alert("Update with success");
    } catch (error) {
      alert(error.message);
    }
  }

  useEffect(() => {
    const getUser = async () => {
      let infoUser = await userRequest.get(`/users/${user.id}`);
      setUserInfos(infoUser.data);
      setUserUpdate(infoUser.data);
    };
    getUser();
  }, []);

  console.log(userUpdate);

  return (
    <div className='compte'>
      <div className="part_left">
        <div className="logo">
          <img src={logo} alt="logo" />
        </div>
        <div className="links">
          <NavLink className='link_dashboard' to='/'>
            <BiHomeAlt /> Dashboard
          </NavLink>
          <NavLink className='link_offres' to='/offres'>
            <TfiBag /> Offres
          </NavLink>
          <NavLink className='link_company' to='/company'>
            <RiBookReadLine /> Company
          </NavLink>
          <NavLink className='link_utilisateurs' to='/utilisateurs'>
            <AiOutlineStock /> Utilisateurs
          </NavLink>
          <NavLink className='link_utilisateurs' to='/candidature'>
            <RiDownload2Line /> Candidature
          </NavLink>
          <NavLink className='link_compte' to='/compte'>
            <FaUser /> Mon Compte
          </NavLink>
          <NavLink className='link_compte' to='/' onClick={()=>handleClick()}>
            <FaUser /> Deconnexion
          </NavLink>
        </div>
      </div>
      <div className="part_right">
        <div className="content_right">
          <Namepages title="Pages / Main Mon Compte " />
          <div className="profil">
            <div className="profil_left">
              <div className="form_content">
                <input type="text"
                  placeholder='Nom complet'
                  className='input_name'
                  name="firstname"
                  onChange={handleChange}
                  value={userUpdate.firstname || ""} />
                <input type="email"
                  placeholder='Adresse e-mail'
                  name="email"
                  onChange={handleChange}
                  value={userUpdate.email || ""}
                  className='input_name' />
                <input type="text"
                  placeholder='Ville'
                  name="city"
                  onChange={handleChange}
                  value={userUpdate.city || ""}
                  className='input_name' />
              </div>
              <button className="btn_change" onClick={handleSave}>Enregister</button>
            </div>
            <div className="profil_right">
              <form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                <h2>Changer le mot de passe</h2>
                <div className="from_content">
                  <input
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    type="text" placeholder='Ancien mot de passe' />
                  <input
                    value={newpassword}
                    onChange={(e) => {
                      setNewPassword(e.target.value);
                    }}
                    type="text" placeholder='Nouveau mot de passe' />
                </div>
                <button className="btn_change" type="submit">Enregister</button>
              </form>
            </div>
          </div>
          <Condition />
        </div>
      </div>
    </div>
  )
}

export default Compte;
