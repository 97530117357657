import React, { useEffect, useState } from 'react'
import './utilisateurs.css'
import logo from '../../assets/images/logo.jpeg'
import { NavLink } from 'react-router-dom'
import { BiHomeAlt } from "react-icons/bi";
import { TfiBag } from "react-icons/tfi";
import { AiOutlineStock } from "react-icons/ai";
import { FaUser } from "react-icons/fa6";
import { RiBookReadLine, RiDownload2Line } from "react-icons/ri";
import Namepages from '../../compotences/namepages/Namepages';
import Condition from '../../compotences/condition/Condition';
import { DataGrid } from '@mui/x-data-grid';
import { MdDelete, MdEdit, MdAdd } from "react-icons/md"; // Importation des icônes de modification et de suppression
import axios from "axios";
import Showmodal from '../../compotences/showmodal/Showmodal';
import { useDispatch } from "react-redux";
import { loGout } from '../../redux/apiCalls';


const Utilisateurs = ({ }) => {

  const dispatch = useDispatch();


  const handleClick = () => {
    loGout(dispatch);
  };

  const [viewModal, setViewModal] = useState(false);

  const [receivedata, setReceiveData] = useState("");

  const handleDelete = async (params) => {
    try {
      const res = await axios.delete(
        `http://localhost:5050/api/users/${params}`
      );
      const response = await axios.get(
        `http://localhost:5050/api/users`
      );
      setAllUsers(response.data)
    } catch (err) {
      console.log(err)
    }
    
  };

  

  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'firstname', headerName: 'Nom', width: 130 },
    { field: 'email', headerName: 'email', type: 'email', width: 190, },
    { field: 'city', headerName: 'Ville', width: 110 },
    { field: 'description', headerName: 'Description', width: 210 },
    { field: 'role', headerName: 'Role', width: 70 },
    { field: 'cv', headerName: 'Cv', width: 50 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 210,
      renderCell: (params) => (
        <div className='space_icon'>
          <button onClick={() => {
            setViewModal(true)
            setReceiveData(params.row)
          }}>
            <MdEdit />
          </button>
          <button onClick={() => {
            handleDelete(params.row.id)
          }}>
            <MdDelete />
          </button>
        </div>
      ),
    },
  ];

  const [allUsers, setAllUsers] = useState([])

  useEffect(() => {
    const handleSend = async () => {
      try {
        const response = await axios.get(
          `http://localhost:5050/api/users`
        );
        setAllUsers(response.data)
      } catch (err) {
        console.log(err)
      }
    };

    handleSend()
  }, []);

  return (
    <div className='utilisateurs'>
      <div className="part_left">
        <div className="logo">
          <img src={logo} alt="Logo" />
        </div>
        <div className="links">
          <NavLink className='link_dashboard' to='/'>
            <BiHomeAlt /> Dashboard
          </NavLink>
          <NavLink className='link_offres' to='/offres'>
            <TfiBag /> Offres
          </NavLink>
          <NavLink className='link_company' to='/company'>
            <RiBookReadLine /> Company
          </NavLink>
          <NavLink className='link_utilisateurs' to='/utilisateurs'>
            <AiOutlineStock /> Utilisateurs
          </NavLink>
          <NavLink className='link_utilisateurs' to='/candidature'>
            <RiDownload2Line /> Candidature
          </NavLink>
          <NavLink className='link_compte' to='/compte'>
            <FaUser /> Mon Compte
          </NavLink>
          <NavLink className='link_compte' to='/' onClick={()=>handleClick()}>
            <FaUser /> Deconnexion
          </NavLink>
        </div>
      </div>
      <div className="part_right">
        <div className="container_right">
          <Namepages title="Pages / Main Utilisateurs" />
          <div className="content_utilisateur">
            <div style={{ height: 354, width: '100%' }}>
              <DataGrid
                rows={allUsers}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 5 },
                  },
                }}
                pageSizeOptions={[5, 10]}
                checkboxSelection
              />
            </div>
          </div>
          <Condition />
        </div>
      </div>
      {viewModal && (<Showmodal Modal={setViewModal} Data={receivedata} setAllUsers={setAllUsers}/>)}
    </div>
  )
}

export default Utilisateurs;
