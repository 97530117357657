import React, { useEffect, useState } from 'react'
import './company.css'
import logo from '../../assets/images/logo.jpeg'
import { NavLink } from 'react-router-dom'
import { BiHomeAlt } from "react-icons/bi";
import { TfiBag } from "react-icons/tfi";
import { AiOutlineStock } from "react-icons/ai";
import { FaUser } from "react-icons/fa6";
import { RiBookReadLine } from "react-icons/ri";
import Namepages from '../../compotences/namepages/Namepages';
import Condition from '../../compotences/condition/Condition';
import { MdDelete, MdEdit, MdAdd } from "react-icons/md"; // Importation des icônes de modification et de suppression
import { DataGrid } from '@mui/x-data-grid';
import axios from "axios";
import Companymodal from '../../compotences/companymodal/Companymodal';
import { RiAddCircleFill, RiDownload2Line } from "react-icons/ri";
import Addcompany from '../../compotences/addcompany/Addcompany';
import { useDispatch } from "react-redux";
import { loGout } from '../../redux/apiCalls';


const Company = () => {

  const dispatch = useDispatch();


  const handleClick = () => {
    loGout(dispatch);
  };


  const [viewModal, setViewModal] = useState(false);

  const [addCompModal, setAddCompModal] = useState(false);

  const [receivedata, setReceiveData] = useState("");

  const [allCompanys, setAllCompanys] = useState([])

  const handleDelete = async (params) => {
    try {
      const res = await axios.delete(
        `http://localhost:5050/api/company/${params}`
      );
      const response = await axios.get(
        `http://localhost:5050/api/company`
      );
      setAllCompanys(response.data)
    } catch (err) {
      console.log(err)
    }
    
  };
  handleDelete()

  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'logo', headerName: 'Logo', width: 130 },
    { field: 'addres', headerName: 'Adresse', width: 210, },
    { field: 'responsible', headerName: 'Responsable', width: 130 },
    { field: 'domain', headerName: 'Domain', width: 150 },
    { field: 'description', headerName: 'Description', width: 220 },
    { field: 'city', headerName: 'City', width: 120 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 210,
      renderCell: (params) => (
        <div className='space_icon'>
          <button onClick={() => {
            setViewModal(true)
            setReceiveData(params.row)
          }}>
            <MdEdit />
          </button>
          <button
          onClick={() => {
            handleDelete(params.row.id)
          }}
          >
            <MdDelete />
          </button>
        </div>
      ),
    },
  ];

  useEffect(() => {
    const handleSend = async () => {
      try {
        const response = await axios.get(
          `http://localhost:5050/api/company`
        );
        setAllCompanys(response.data)
      } catch (err) {
        console.log(err)
      }
    };

    handleSend()
  }, []);

  return (
    <div className='company'>
      <div className="part_left">
        <div className="logo">
          <img src={logo} />
        </div>
        <div className="links">
          <NavLink className='link_dashboard' to='/'>
            <BiHomeAlt /> Dashboard
          </NavLink>
          <NavLink className='link_offres' to='/offres'>
            <TfiBag /> Offres
          </NavLink>
          <NavLink className='link_company' to='/company'>
            <RiBookReadLine /> Company
          </NavLink>
          <NavLink className='link_utilisateurs' to='/utilisateurs'>
            <AiOutlineStock /> Utilisateurs
          </NavLink>
          <NavLink className='link_utilisateurs' to='/candidature'>
            <RiDownload2Line /> Candidature
          </NavLink>
          <NavLink className='link_compte' to='/compte'>
            <FaUser /> Mon Compte
          </NavLink>
          <NavLink className='link_compte' to='/' onClick={()=>handleClick()}>
            <FaUser /> Deconnexion
          </NavLink>
        </div>
      </div>
      <div className="part_right">
        <div className="container_right">
          <Namepages title="Pages / Main Company " />
          <div className="btn_add">
            <button className='add_offer'>
              Ajouter une companie
              <RiAddCircleFill 
               onClick={() => {
                setAddCompModal(true)
              }} />
            </button>
          </div>
          <div className="content_company">
            <div style={{ height: 354, width: '100%' }}>
              <DataGrid
                rows={allCompanys}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 5 },
                  },
                }}
                pageSizeOptions={[5, 10]}
                checkboxSelection
              />
            </div>
          </div>
          <Condition />
        </div>
      </div>
      {viewModal && (<Companymodal Modal={setViewModal} Data={receivedata} setAllCompanys={setAllCompanys} />)}
      {addCompModal && (<Addcompany Modal={setViewModal}  setAddCompModal={setAddCompModal} setAllCompanys={setAllCompanys} />)}
    </div>
  )
}

export default Company