import React, { useEffect, useState } from 'react'
import './dashboard.css'
import logo from '../../assets/images/logo.jpeg'
import { NavLink } from 'react-router-dom'
import { BiHomeAlt } from "react-icons/bi";
import { TfiBag } from "react-icons/tfi";
import { AiOutlineStock } from "react-icons/ai";
import { FaUser } from "react-icons/fa6";
import { RiBookReadLine, RiDownload2Line } from "react-icons/ri";
import Namepages from '../../compotences/namepages/Namepages';
import Condition from '../../compotences/condition/Condition';
import { useDispatch } from "react-redux";
import { loGout } from '../../redux/apiCalls';
import axios from 'axios';


const Dashboard = () => {


  const dispatch = useDispatch();

  const handleClick = () => {
    loGout(dispatch);
  };

  const [allInfos, setAllInfos] = useState([])

  useEffect(() => {
      const handleSend = async () => {
          try {
              const response = await axios.get(
                  `http://localhost:5050/api/all`
              );
              setAllInfos(response.data)
          } catch (err) {
              console.log(err)
          }
      };
      handleSend()
  }, []);


  return (
    <div className='dashboard'>
      <div className="part_left">
        <div className="logo">
          <img src={logo} />
        </div>
        <div className="links">
          <NavLink className='link_dashboard' to='/'>
            <BiHomeAlt /> Dashboard
          </NavLink>
          <NavLink className='link_offres' to='/offres'>
            <TfiBag /> Offres
          </NavLink>
          <NavLink className='link_company' to='/company'>
            <RiBookReadLine /> Company
          </NavLink>
          <NavLink className='link_utilisateurs' to='/utilisateurs'>
            <AiOutlineStock /> Utilisateurs
          </NavLink>
          <NavLink className='link_utilisateurs' to='/candidature'>
            <RiDownload2Line /> Candidature
          </NavLink>
          <NavLink className='link_compte' to='/compte'>
            <FaUser /> Mon Compte
          </NavLink>
          <NavLink className='link_compte' to='/' onClick={()=>handleClick()}>
            <FaUser /> Deconnexion
          </NavLink>
        </div>
      </div>
      <div className="part_right">
        <div className="container_right">
          <Namepages title="Pages / Main Dashboard " />
          <div className="content">
            <div className="block_offres">
              <div>
                <p>Nombre(s) Offres</p>
              </div>
              <div>
                <p className='size_number'>{allInfos.offerNumber}</p>
              </div>
            </div>
            <div className="block_company">
              <div>
                <p>Nombre(s) Companies</p>
              </div>
              <div>
                <p className='size_number'>{allInfos.companyNumber}</p>
              </div>
            </div>
            <div className="block_compte">
              <div>
                <p>Nombre(s) Candidats</p>
              </div>
              <div>
                <p className='size_number'>{allInfos.candidatureNumber}</p>
              </div>
            </div>
          </div>
          <div className="main_dashboard">
            {/* <div className="dashboard_left"></div>
            <div className="dashboard_right"></div> */}
          </div>
          <Condition />
        </div>
      </div>
    </div>
  )
}

export default Dashboard