import React, { useState } from 'react';
import "./posts.css";
import { RiEditCircleFill } from "react-icons/ri";
import { RiDeleteBin6Fill } from "react-icons/ri";
import axios from 'axios';


const Post = ({ offer, setOfferModal, setReceiveOffer, setOffers }) => {

    const handleDelete = async (params) => {
        try {
            const res = await axios.delete(
                `http://localhost:5050/api/offres/${params}`
            );
            const response = await axios.get('http://localhost:5050/api/offres/');
            setOffers(response.data)
        } catch (err) {
            console.log(err)
        }

    };


    return (
        <div className='post_content'>
            <div className='items_space'>
                <h2>{offer.title}</h2>
                <div className="icon_option">
                    <RiEditCircleFill className='circle_fill'
                        onClick={() => {
                            setOfferModal(true)
                            setReceiveOffer(offer)
                        }}
                    />
                    <RiDeleteBin6Fill className='circle_fill'
                        onClick={() => {
                            handleDelete(offer.id)
                        }} />
                </div>

            </div>
            <div className="post_container">
                <div className='column1'>
                    <p>Lieu : {offer.location}</p>
                    <p>Salaire : {offer.salary}</p>
                    <p>Rythme : {offer.rhythm}</p>
                </div>
                <div className='column2'>
                    <p>Offre : {offer.type}</p>
                    <p>Date limite : {offer.limit_date}</p>
                </div>
                <div className='column3'>
                    <p>{offer.description}</p>
                </div>
            </div>
        </div>
    );
}

export default Post;
