import React from 'react'
import './namepages.css'

const Namepages = (props) => {
    return (
      <div>
        <p>{props.title}</p>
      </div>
    );
  }

export default Namepages