import React, { useEffect, useState } from 'react'
import './style.css'
import { IoIosCloseCircle } from 'react-icons/io'
import axios from 'axios';

const Addoffer = ({ setAddOffer, setOffers }) => {

    const [companys, setCompanys] = useState([])

    const [formData, setFormData] = useState({
        title: '',
        location: '',
        type: '',
        salary: '',
        limit_date: '',
        rhythm: '',
        company: '',
        description: ''
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSave = async () => {
        try {
            await axios.post(`http://localhost:5050/api/offres/`, formData);
            const response = await axios.get(
                `http://localhost:5050/api/offres`
            );
            setOffers(response.data)
            setAddOffer(false)
            console.log("ok")
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
       const getComp = async() =>{
        const response = await axios.get(
            `http://localhost:5050/api/company`
          );
          setCompanys(response.data)
       }
       getComp()
        
    }, [])
    



    return (
        <div className='content_modal'>
            <IoIosCloseCircle className='btn_close'
                onClick={() => { setAddOffer(false) }}
            />
            <div class="modal">
                <p>Ajouter une offre</p>
                <div class="separator">
                    <div class="credit-card-info--form">
                        <div class="input_container">
                            <div className="col-items">
                                <label class="input_label">Titre de l'offre</label>
                                <input class="input_field" type="text" name='title' value={formData.title} onChange={handleInputChange} placeholder="Enter your full name" />
                            </div>
                            <div className="col-items">
                                <label class="input_label">Lieu</label>
                                <input class="input_field" type="text" name="location" value={formData.location} onChange={handleInputChange} placeholder="Enter your full name" />
                            </div>
                            <div className="col-items">
                                <label class="input_label">Offre</label>
                                <input class="input_field" type="text" name="type" value={formData.type} onChange={handleInputChange} placeholder="Enter your full name" />
                            </div>
                        </div>
                        <div class="input_container">
                            <div className="col-items">
                                <label class="input_label">Saliare</label>
                                <input class="input_field" type="text" name="salary" value={formData.salary} onChange={handleInputChange} placeholder="Enter your full name" />
                            </div>
                            <div className="col-items">
                                <label class="input_label">Date</label>
                                <input class="input_field" type="text" name="limit_date" value={formData.limit_date} onChange={handleInputChange} placeholder="Enter your full name" />
                            </div>
                            <div className="col-items">
                                <label class="input_label">Rythme</label>
                                <input class="input_field" type="text" name="rhythm" value={formData.rhythm} onChange={handleInputChange} placeholder="Enter your full name" />
                            </div>
                        </div>
                        <div class="input_container">
                            <div className="col-items">
                                <div>
                                    <label htmlFor="selectOption">Choissir une entreprise:</label>
                                    <select id="selectOption" name="company" value={formData.company} onChange={handleInputChange}>
                                        <option value="">Sélectionnez...</option>
                                        {companys.map(company =>(
                                            <option value={company.name}>{company.name}</option>
                                        ))}
                                    </select>
                                    {/* {selectedOption && <p>Vous avez sélectionné : {selectedOption}</p>} */}
                                </div>
                                <label class="input_label">Description</label>
                                <textarea class="textaera_field" required="" value={formData.description} onChange={handleInputChange} name="description">
                                </textarea>
                            </div>
                        </div>
                        <div className='btn_position'>
                            <button class="purchase--btn" onClick={handleSave}>
                                Enregister
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Addoffer