import React, { useState } from 'react'
import './style.css'
import { IoIosCloseCircle } from "react-icons/io";
import axios from 'axios';

const Showmodal = ({ Modal, Data, setAllUsers}) => {

    const [userUpdate, setUserUpdate] = useState(Data);

    const handleChange = ({ currentTarget }) => {
        const { name, value } = currentTarget;
        setUserUpdate({ ...userUpdate, [name]: value });
    };

    const updateUser = async () => {
        try {
            await axios.put(`http://localhost:5050/api/users/${userUpdate.id}`, userUpdate);
            const response = await axios.get(
                `http://localhost:5050/api/users`
            );
            setAllUsers(response.data)
            Modal(false)
        } catch (error) {
            console.error(error);
        }
    }


    return (
        <div className='content_modal'>
            <IoIosCloseCircle className='btn_close'
                onClick={() => { Modal(false) }} />
            <div class="modal">
                <p>Modifier le l'utilisateur</p>
                <div class="separator">
                    <div class="credit-card-info--form">
                        <div class="input_container">
                            <div className="space_input">
                                <div className="col-items">
                                    <label class="input_label">Nom</label>
                                    <input class="input_field"  value={userUpdate.firstname} onChange={handleChange} type="text" name="firstname" placeholder="Enter your full name" />
                                </div>
                                <div className="col-items">
                                    <label class="input_label">Email</label>
                                    <input class="input_field"  value={userUpdate.email} onChange={handleChange} type="text" name="email" placeholder="Enter your full name" />
                                </div>
                            </div>
                        </div>
                        <div class="input_container">
                            <div className="col-items">
                                <label class="input_label">Ville</label>
                                <input class="input_field"  value={userUpdate.city} onChange={handleChange}  type="text" name="city" placeholder="Enter your full name" />
                            </div>
                            <div className="col-items">
                                <label class="input_label">Role</label>
                                <input class="input_field"  value={userUpdate.role} onChange={handleChange}  type="text" name="role" placeholder="Enter your full name" />
                            </div>
                            <div className="col-items">
                                <label class="input_label">CV</label>
                                <input class="input_field"  value={userUpdate.cv} onChange={handleChange}  type="text" name="cv" placeholder="Enter your full name" />
                            </div>
                        </div>
                        <div class="input_container">
                            <div className="col-items">
                                <label class="input_label">Description</label>
                                <textarea class="textaera_field"  value={userUpdate.description} onChange={handleChange}  required="" name="description">
                                </textarea>
                            </div>
                        </div>
                        <div className='btn_position'>
                            <button class="purchase--btn" onClick={() => updateUser()}>
                                Envoyer
                            </button>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    )
}

export default Showmodal