import "./App.css";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import Dashboard from "./pages/dashboard/Dashboard";
import Compte from "./pages/compte/Compte";
import Utilisateurs from "./pages/utilisateurs/Utilisateurs";
import Company from "./pages/company/Company";
import Offres from "./pages/offres/Offres";
import Login from "./pages/authentification/login/Login";
import Forgetpass from "./pages/authentification/forgetpass/Forgetpass";
import Register from "./pages/authentification/register/Register";
import axios from "axios";
import Candidats from "./pages/candidat/Candidats";

function App() {
  const user = useSelector((state) => state.user.currentUser);

  const userRequest = axios.create({
    baseURL: "http://localhost:5050/api",
    headers: { token: `Bearer ${user?.Token}` },
  });


  return (
    <div className="App">
      {user ? (
        <>
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/offres" element={<Offres />} />
            <Route path="/company" element={<Company />} />
            <Route path="/utilisateurs" element={<Utilisateurs />} />
            <Route path="/candidature" element={<Candidats />} />
            <Route path="/compte" element={<Compte userRequest={userRequest} user={user} />} />
            <Route path="/Forgetpass" element={<Forgetpass />} />
          </Routes>
        </>
      ) : (
        < Login/>
      )}
    </div>
  );
}

export default App;
