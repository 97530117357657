import React, { useEffect, useState } from 'react'
import './offres.css'
import logo from '../../assets/images/logo.jpeg'
import { NavLink } from 'react-router-dom'
import { BiHomeAlt } from "react-icons/bi";
import { TfiBag } from "react-icons/tfi";
import { AiOutlineStock } from "react-icons/ai";
import { FaUser } from "react-icons/fa6";
import { RiAddCircleFill, RiBookReadLine, RiDownload2Line } from "react-icons/ri";
import Namepages from '../../compotences/namepages/Namepages';
import Condition from '../../compotences/condition/Condition';
import Posts from '../../compotences/posts/Posts';
import axios from 'axios';

import Offermodal from '../../compotences/offermodal/Offermodal';
import Addoffer from '../../compotences/addoffer/Addoffer';
import { useDispatch } from "react-redux";
import { loGout } from '../../redux/apiCalls';


const Offres = (offer) => {

  const dispatch = useDispatch();


  const handleClick = () => {
    loGout(dispatch);
  };


  const [offers, setOffers] = useState([]);
  const [offerModal, setOfferModal] = useState(false);
  const [receiveoffer, setReceiveOffer] = useState("");
  const [deleteoffer, setDeleteOffer] = useState("");

  const [addOffer, setAddOffer] = useState(false)


 

  useEffect(() => {
    async function allOffers() {
      try {
        const response = await axios.get('http://localhost:5050/api/offres/');
        setOffers(response.data)
      } catch (error) {
        console.error(error);
      }
    }
    allOffers()
  }, [])

  return (
    <div className='offres'>
      <div className="part_left">
        <div className="logo">
          <img src={logo} />
        </div>
        <div className="links">
          <NavLink className='link_dashboard' to='/'>
            <BiHomeAlt /> Dashboard
          </NavLink>
          <NavLink className='link_offres' to='/offres'>
            <TfiBag /> Offres
          </NavLink>
          <NavLink className='link_company' to='/company'>
            <RiBookReadLine /> Company
          </NavLink>
          <NavLink className='link_utilisateurs' to='/utilisateurs'>
            <AiOutlineStock /> Utilisateurs
          </NavLink>
          <NavLink className='link_utilisateurs' to='/candidature'>
            <RiDownload2Line /> Candidature
          </NavLink>
          <NavLink className='link_compte' to='/compte'>
            <FaUser /> Mon Compte
          </NavLink>
          <NavLink className='link_compte' to='/' onClick={()=>handleClick()}>
            <FaUser /> Deconnexion
          </NavLink>
        </div>
      </div>
      <div className="part_right">
        <div className="container_right">
          <Namepages title="Pages / Main Offres" />
          <div className="btn_add">
            <button className='add_offer'>
              Ajouter une offre
              <RiAddCircleFill 
               onClick={() => {
                setAddOffer(true)
              }} />
            </button>
          </div>
          <div className="container_offres">
            <div className="elements_offres">
              {
                offers.map(offer => (
                  <Posts
                    offer={offer}
                    setOfferModal={setOfferModal}
                    setReceiveOffer={setReceiveOffer}
                    setOffers={setOffers}
                  />
                ))
              }
            </div>
          </div>
          <Condition />
        </div>
      </div>
      {offerModal && (<Offermodal Modal={setOfferModal} Data={receiveoffer} setOffers={setOffers} />)}
      {addOffer && (<Addoffer setAddOffer={setAddOffer} setOffers={setOffers} />)}
    </div>
  )
}

export default Offres