import React from 'react'
import './candidats.css'
import logo from '../../assets/images/logo.jpeg'
import { NavLink } from 'react-router-dom'
import { BiHomeAlt } from "react-icons/bi";
import { TfiBag } from "react-icons/tfi";
import { AiOutlineStock } from "react-icons/ai";
import { FaUser } from "react-icons/fa6";
import { RiBookReadLine } from "react-icons/ri";
import Namepages from '../../compotences/namepages/Namepages';
import Condition from '../../compotences/condition/Condition';
import { MdDelete, MdEdit, MdAdd } from "react-icons/md"; // Importation des icônes de modification et de suppression
import { DataGrid } from '@mui/x-data-grid';
import { RiDownload2Line } from "react-icons/ri";
import { useState } from 'react';
import { useEffect } from 'react';
import axios from "axios";
import { useDispatch } from "react-redux";
import { loGout } from '../../redux/apiCalls';


const Candidats = ({ }) => {

    const dispatch = useDispatch();


    const handleClick = () => {
        loGout(dispatch);
    };



    const handleDelete = async (params) => {
        try {
            const res = await axios.delete(
                `http://localhost:5050/api/candidatures/${params}`
            );
            const response = await axios.get(
                `http://localhost:5050/api/candidatures/`
            );
            setAllCandidats(response.data)
        } catch (err) {
            console.log(err)
        }

    };


    const columns = [
        { field: 'nom', headerName: 'Nom(s)', width: 70 },
        { field: 'prenom', headerName: 'Prenom(s)', width: 130 },
        { field: 'email', headerName: 'Email', width: 210, },
        { field: 'pays', headerName: 'Pays', width: 130 },
        { field: 'telephone', headerName: 'Téléphone', width: 150 },
        { field: 'adresse_complete', headerName: 'Adresse complète', width: 220 },
        { field: 'expriences', headerName: 'Experiences', width: 120 },
        { field: 'niveau_etude', headerName: "Niveau d'étude", width: 120 },
        { field: 'etat', headerName: "Etat", width: 120 },
        { field: 'type_de_contrat', headerName: "Type de contrat", width: 120 },
        { field: 'offerId', headerName: "offre Id", width: 120 },
        { field: 'cv', headerName: 'CV', width: 120 },

        {
            field: 'actions',
            headerName: 'Actions',
            width: 210,
            renderCell: (params) => (
                <div className='space_icons'>
                    <button className='space_btn'
                        onClick={() => {
                            handleDelete(params.row.id)
                        }}
                    >
                        <MdDelete />
                    </button>
                </div>
            ),
        },
    ];

    const [allCandidats, setAllCandidats] = useState([])

    useEffect(() => {
        const handleSend = async () => {
            try {
                const response = await axios.get(
                    `http://localhost:5050/api/candidatures`
                );
                setAllCandidats(response.data)
            } catch (err) {
                console.log(err)
            }
        };

        handleSend()
    }, []);


    return (
        <div className='company'>
            <div className="part_left">
                <div className="logo">
                    <img src={logo} />
                </div>
                <div className="links">
                    <NavLink className='link_dashboard' to='/'>
                        <BiHomeAlt /> Dashboard
                    </NavLink>
                    <NavLink className='link_offres' to='/offres'>
                        <TfiBag /> Offres
                    </NavLink>
                    <NavLink className='link_company' to='/company'>
                        <RiBookReadLine /> Company
                    </NavLink>
                    <NavLink className='link_utilisateurs' to='/utilisateurs'>
                        <AiOutlineStock /> Utilisateurs
                    </NavLink>
                    <NavLink className='link_utilisateurs' to='/candidature'>
                        <RiDownload2Line /> Candidature
                    </NavLink>
                    <NavLink className='link_compte' to='/compte'>
                        <FaUser /> Mon Compte
                    </NavLink>
                    <NavLink className='link_compte' to='/' onClick={() => handleClick()}>
                        <FaUser /> Deconnexion
                    </NavLink>
                </div>
            </div>
            <div className="part_right">
                <div className="container_right">
                    <Namepages title="Pages / Main Candidatures" />

                    <div className="content_company">
                        <div style={{ height: 354, width: '100%' }}>
                            <DataGrid
                                rows={allCandidats}
                                columns={columns}
                                initialState={{
                                    pagination: {
                                        paginationModel: { page: 0, pageSize: 5 },
                                    },
                                }}
                                pageSizeOptions={[5, 10]}
                                checkboxSelection
                            />
                        </div>
                    </div>
                    <Condition />
                </div>
            </div>
        </div>
    )
}

export default Candidats