import React, { useState } from 'react'
import "./style.css"
import { IoIosCloseCircle } from "react-icons/io";
import axios from 'axios';

const Addcompany = ({setAddCompModal, setAllCompanys}) => {


    const [formData, setFormData] = useState({
        name: '',
        logo: '',
        address: '',
        responsible: '',
        domain: '',
        city: '',
        description: ''
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSave = async () => {
        try {
            await axios.post(`http://localhost:5050/api/company`, formData);
            const response = await axios.get(
                `http://localhost:5050/api/company`
            );
            setAllCompanys(response.data)
            setAddCompModal(false)
            console.log("ok")
        } catch (error) {
            console.error(error);
        }
    }







  return (
    <div className='content_modal'>
            <IoIosCloseCircle className='btn_close'
                onClick={() => { setAddCompModal(false) }} />
            <div class="modal">
                <p>Ajouter une entreprise</p>
                <div class="separator">
                    <div class="credit-card-info--form">
                        <div class="input_container">
                            <div className="col-items">
                                <label class="input_label">Nom de l'entreprise</label>
                                <input class="input_field" name="name" 
                                    type="text" value={formData.name} onChange={handleInputChange} title="Inpit title" placeholder="Enter your full name" />
                            </div>
                            <div className="col-items">
                                <label class="input_label">Logo</label>
                                <input class="input_field"  type="text" name="logo" value={formData.logo} onChange={handleInputChange} title="Inpit title" placeholder="Enter your full name" />
                            </div>
                            <div className="col-items">
                                <label class="input_label">Adresse</label>
                                <input class="input_field"  type="text" name="address" value={formData.address} onChange={handleInputChange} title="Inpit title" placeholder="Enter your full name" />
                            </div>
                        </div>
                        <div class="input_container">
                            <div className="col-items">
                                <label class="input_label">Ville</label>
                                <input class="input_field"  type="text" name="city" value={formData.city} onChange={handleInputChange} title="Inpit title" placeholder="Enter your full name" />
                            </div>
                            <div className="col-items">
                                <label class="input_label">Domaine</label>
                                <input class="input_field"  type="text" name="domain" value={formData.domain} onChange={handleInputChange} title="Inpit title" placeholder="Enter your full name" />
                            </div>
                            <div className="col-items">
                                <label class="input_label">Responsable</label>
                                <input class="input_field"  type="text" name="responsible" value={formData.responsible} onChange={handleInputChange} title="Inpit title" placeholder="Enter your full name" />
                            </div>
                        </div>
                        <div class="input_container">
                            <div className="col-items">
                                <label class="input_label">Description</label>
                                <textarea class="textaera_field"  required="" value={formData.description} onChange={handleInputChange} name="description">
                                </textarea>
                            </div>
                        </div>
                        <div className='btn_position'>
                            <button class="purchase--btn" onClick={handleSave} >
                                Enregistrer
                            </button>
                        </div>

                    </div>

                </div>
            </div>
        </div>
  )
}

export default Addcompany