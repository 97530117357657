import React from 'react'
import './forgetpass.css'
import logo from '../../../assets/images/logo.jpeg'
import { Link } from 'react-router-dom';
import Login from "../login/Login";

const Forgetpass = () => {
  return (
    <div className='forgetpass'>
      <div className="part_left">
        <img src={logo} />
      </div>
      <div className="part_right">
        <div className="container_formulaire">
          <h1>Modifier votre mot de passe</h1>
          <p>Restorer votre mot de passe et acceder au panel admin!</p>
          <form action="">
            <input type="password" placeholder='Ancien mot de passe' required />
            <input type="password" placeholder='Entrez votre nouveau mot de passe' required />
            <p>
              <Link to="/Login">Connexion?</Link>
            </p>
            <button>Enregistrer</button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Forgetpass