import React, { useState } from 'react'
import './style.css'
import { IoIosCloseCircle } from 'react-icons/io'
import axios from 'axios';


const Offermodal = ({Modal, Data, setOffers}) => {

    const [offerUpdate, setOfferUpdate] = useState(Data);

    const handleChange = ({ currentTarget }) => {
        const { name, value } = currentTarget;
        setOfferUpdate({ ...offerUpdate, [name]: value });
    };

    const updateOffers = async () => {
        try {
            await axios.put(`http://localhost:5050/api/offres/${offerUpdate.id}`, offerUpdate);
            const response = await axios.get(
                `http://localhost:5050/api/offres`
            );
            setOffers(response.data)
            Modal(false)
            console.log("ok")
        } catch (error) {
            console.error(error);
        }
    }


  return (
    <div className='content_modal'>
            <IoIosCloseCircle className='btn_close'
                onClick={() => { Modal(false) }} />
            <div class="modal">
                <p>Modifier l'offre</p>
                <div class="separator">
                    <div class="credit-card-info--form">
                        <div class="input_container">
                            <div className="col-items">
                                <label class="input_label">Titre de l'offre</label>
                                <input class="input_field" type="text" name='title' value={offerUpdate.title} onChange={handleChange}  placeholder="Enter your full name" />
                            </div>
                            <div className="col-items">
                                <label class="input_label">Lieu</label>
                                <input class="input_field" type="text" name="location" value={offerUpdate.location} onChange={handleChange}  placeholder="Enter your full name" />
                            </div>
                            <div className="col-items">
                                <label class="input_label">Offre</label>
                                <input class="input_field"  type="text" name="type" value={offerUpdate.type} onChange={handleChange} placeholder="Enter your full name" />
                            </div>
                        </div>
                        <div class="input_container">
                            <div className="col-items">
                                <label class="input_label">Saliare</label>
                                <input class="input_field" type="text" name="salary" value={offerUpdate.salary} onChange={handleChange} placeholder="Enter your full name" />
                            </div>
                            <div className="col-items">
                                <label class="input_label">Date</label>
                                <input class="input_field"  type="text" name="limit_date" value={offerUpdate.limit_date} onChange={handleChange} placeholder="Enter your full name" />
                            </div>
                            <div className="col-items">
                                <label class="input_label">Rythme</label>
                                <input class="input_field"  type="text" name="rhythm" value={offerUpdate.rhythm} onChange={handleChange} placeholder="Enter your full name" />
                            </div>
                        </div>
                        <div class="input_container">
                            <div className="col-items">
                                <label class="input_label">Description</label>
                                <textarea class="textaera_field" required="" value={offerUpdate.description} onChange={handleChange} name="description">
                                </textarea>
                            </div>
                        </div>
                        <div className='btn_position'>
                            <button class="purchase--btn" onClick={() => updateOffers()}>
                                Envoyer
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default Offermodal